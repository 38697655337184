/**
 * Checks if a file is png
 * @param file
 */
import {
  AuthorizeWebResponse,
  ContainerAddressCreateDto,
  CustomerHeaderDto,
  FractionHeaderDto,
  HardwareUnitHeaderDto,
  PermissionGroupHeaderDto,
  UserHeaderDto,
  UserListDto,
} from "../eGate-API";
import { MemberItem } from "../components/MemberItem";
import { ApiError } from "./eGateApi";
import camelCase from "lodash/camelCase";
import dayjs from "dayjs";
import GoogleAddressParser from "../components/utility/GoogleAddressParser";
import { shortTimeFormat } from "./timeUtils";

import duration from "dayjs/plugin/duration";
import entities from "../Entities";
import { ROLE } from "../Auth/useRole";
dayjs.extend(duration);

/**
 * Determines if the given file is a PNG image.
 *
 * @param {File|undefined} file - The file to check.
 * @return {boolean} Returns true if the file is a PNG image, false otherwise.
 */
export function isPng(file: File | undefined) {
  return file?.type === "image/png";
}

/**
 * Checks if a file is in CSV format.
 *
 * @param {File | undefined} file - The file to check.
 *
 * @return {boolean} - True if the file is in CSV format, false otherwise.
 */
export function isCsv(file: File | undefined) {
  return file?.name.endsWith(".csv") || file?.type === "text/csv" || file?.type === "application/vnd.ms-excel";
}

/**
 * Joins an array of strings using a delimiter, or returns the input as-is if it's not an array.
 *
 * @param {string | string[] | undefined | number} obj - The input to be joined.
 * @param {string} delimiter - The delimiter to be used for joining strings.
 * @returns {string} - The joined string if the input is an array, otherwise the input as a string.
 */
export function joinIfArray(obj: string | string[] | undefined | number, delimiter: string): string {
  if (Array.isArray(obj)) {
    return obj.join(delimiter);
  }
  return obj as string;
}

export function defaultHandler(e: any) {
  const isProduction = import.meta.env.PROD;
  if (!isProduction) {
    console.log(e);
  }
}

/**
 * Converts UserListDto[] to MemberItem[]
 * @param userList
 */
export function usersToMemberEdit(userList?: UserListDto[]): MemberItem[] {
  if (!userList) {
    return [];
  }
  return userList.map((user: UserListDto) => ({
    ...user,
    name: user.username,
    id: user.id,
  }));
}

export function qsTypeDecoder() {
  return (str: string, decoder: any, charset: string) => {
    const strWithoutPlus = str.replace(/\+/g, " ");
    if (charset === "iso-8859-1") {
      // unescape never throws, no try...catch needed:
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }

    function isPaddedNumber() {
      return str[0] == "0" && str.length > 1;
    }

    if (!isPaddedNumber() && /^(\d+|\d*\.\d+)$/.test(str)) {
      return parseFloat(str);
    }

    const keywords = {
      true: true,
      false: false,
      null: null,
      undefined,
    };
    if (str in keywords) {
      // @ts-ignore
      return keywords[str];
    }

    // utf-8
    try {
      return decodeURIComponent(strWithoutPlus);
    } catch (e) {
      return strWithoutPlus;
    }
  };
}

export function mapToReference(arr: any[]) {
  return arr?.map((value) => ({ id: value.id }));
}

export const emzPrimaryColor = "var(--primary)";

export interface FormFieldType {
  errors: string[];
  name: any;
}

export function apiErrorsToFieldErrors(apiValidationErrors: ApiError): FormFieldType[] {
  const fields: FormFieldType[] = [];
  if (apiValidationErrors?.errors) {
    Object.keys(apiValidationErrors?.errors).forEach((key) => {
      fields.push({
        // @ts-ignore

        errors: apiValidationErrors?.errors?.[key],
        name: camelCase(key),
      });
    });
  }
  return fields;
}

export function divideIfPresent(value?: number, by = 100) {
  if (value !== undefined && !isNaN(value)) {
    if (value === 0) {
      return 0;
    }
    return value / by;
  }
  return 0;
}

export function multiplyIfPresent(value?: number, floor?: boolean, by = 100) {
  if (value !== undefined && !isNaN(value)) {
    if (value === 0) {
      return 0;
    }
    if (floor) {
      return Math.floor(value * by);
    } else {
      return value * by;
    }
  }
  return 0;
}

export function normalizeMax(value: string, prevValue: string | undefined, max: number) {
  if ((value.length ?? 0) > max) {
    return prevValue;
  }
  return value;
}

export function arrayToHex(list: string[]) {
  return list.map((i) => parseInt(i, 16));
}

function matchingHexFormat(s: string) {
  const regExp = /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/;
  return regExp.test(s);
}
export function normalizeHexNumber(value: string, prevValue?: string) {
  if (value.length == 0) {
    return value;
  }
  const len = value.length > 2;

  if (!matchingHexFormat(value) || len) {
    return prevValue;
  }
  return value;
}
export function normalizeHexString(value: string, prevValue?: string) {
  if (value.length == 0) {
    return value;
  }

  if (!matchingHexFormat(value)) {
    return prevValue;
  }
  return value;
}

/**
 * Allow only numbers and dot and comma
 * Convert comma to dot
 * @param value
 * @param prevValue
 */
export function normalizeCoordinate(value: string, prevValue?: string) {
  function matchingFormat(str: string) {
    return str.match(/^[0-9.,-]+$/);
  }
  if (value.length === 0) {
    return value;
  }
  if (!matchingFormat(value)) {
    return prevValue;
  }
  const str = value.replace(",", ".");
  const count = str.split(".").length - 1;

  if (count > 1) {
    return prevValue;
  }
  return str ?? "";
}

export function sinkEvent(e: any) {
  e.preventDefault();
  e.stopPropagation();
}

export function toHex(num: number) {
  const str = num.toString(16);
  return "0x" + "0".repeat(8 - str.length) + str;
}

export default function durationAsString(seconds: number) {
  const durationVal = dayjs.duration(seconds, "seconds");

  //Get Days
  const days = Math.floor(durationVal.asDays()); // .asDays returns float but we are interested in full days only
  const daysFormatted = days ? `${days}d ` : ""; // if no full days then do not display it at all

  //Get Hours
  const hours = durationVal.hours();
  const hoursFormatted = `${hours}h `;

  //Get Minutes
  const minutes = durationVal.minutes();
  const minutesFormatted = `${minutes}m`;

  return [daysFormatted, hoursFormatted, minutesFormatted].join("");
}

export function minutesToUTCOffset(minutes: number) {
  // Get the absolute number of hours and minutes
  const absoluteHours = Math.floor(Math.abs(minutes) / 60);
  const absoluteMinutes = Math.abs(minutes) % 60;

  // Construct the UTC offset string
  const sign = minutes < 0 ? "-" : "+";
  const hoursString = absoluteHours.toString().padStart(2, "0");
  const minutesString = absoluteMinutes.toString().padStart(2, "0");

  return `UTC${sign}${hoursString}:${minutesString}`;
}

/**
 * Calculates a fluid font size clamp() for screen size
 */
export function fluidType(minFont: number, maxFont: number) {
  return ` clamp(${minFont}px, 1.5vw, ${maxFont}px); `;
}

/**
 * returns true if the string a is in the string b
 * @param a
 * @param b
 */
export function textMatch(a: string, b: string) {
  if (!a || !b) {
    return false;
  }
  return a?.toLowerCase().indexOf(b.toLowerCase()) != -1;
}

function sanitizeAddressPart(str?: string) {
  if (!str) {
    return "";
  }
  if (str.toLowerCase().includes("unnamed")) {
    return "";
  }
  return str;
}

/**
 * Converts google places geo result to ContainerAddressCreateDto
 * @param geoCode
 */
export function convertGoogleResultToAddress(geoCode: any): ContainerAddressCreateDto {
  const parser = new GoogleAddressParser(geoCode.address_components);
  const googleResult = parser.result();
  const location = geoCode?.geometry?.location;
  return {
    city: sanitizeAddressPart(googleResult.city),
    country: sanitizeAddressPart(googleResult.country),
    street: sanitizeAddressPart(googleResult.street_name) + " " + sanitizeAddressPart(googleResult.street_number),
    zipCode: sanitizeAddressPart(googleResult.postal_code),
    location: { lat: location?.lat(), lon: location?.lng() },
  };
}

/**
 *
 * @param filter
 */
export function rangeFilterUTC(filter: { from?: string; to?: string }) {
  return {
    ...filter,
    to: filter?.to ? dayjs(filter?.to).utc().format(shortTimeFormat) : undefined,
    from: filter?.from ? dayjs(filter?.from).utc().format(shortTimeFormat) : undefined,
  };
}

export function sanitizeFilter(input: any) {
  const { page, pageSize, sortOrder, sortBy, culture, t, f, ...result } = input ?? {};

  if (result.customerId == undefined) {
    const { customerId, ...rest } = result;
    return rest;
  }
  return result;
}

export function hasFilter(input: any, selectedCustomer: any) {
  return Object.keys(sanitizeFilter(input)).length > 0 || selectedCustomer;
}

export function notEmpty(val: string | undefined) {
  return !!val && val !== "";
}

export function orZero(val: number | undefined) {
  return val ?? 0;
}

export function hasDuplicates(array: any[]) {
  return new Set(array).size !== array.length;
}
export function filterHardwareUnits(fulltext: string, items: HardwareUnitHeaderDto[]) {
  if (!fulltext) {
    return items;
  }

  function doSearch(i: HardwareUnitHeaderDto) {
    return (
      textMatch(i.name, fulltext) ||
      textMatch(i.container?.appName, fulltext) ||
      textMatch(i.container?.name, fulltext) ||
      textMatch(i.hardwareVersion, fulltext) ||
      textMatch(i.softwareVersion, fulltext) ||
      textMatch(i.container?.bleName, fulltext)
    );
  }
  return items?.filter(doSearch);
}

export function filterPermissionGroups(fulltext: string, items: PermissionGroupHeaderDto[]) {
  if (!fulltext) {
    return items;
  }

  function doSearch(i: PermissionGroupHeaderDto) {
    return textMatch(i.name, fulltext);
  }
  return items?.filter(doSearch);
}

export function filterUsers(fulltext: string, items: UserHeaderDto[]) {
  if (!fulltext) {
    return items;
  }

  function doSearch(i: UserHeaderDto) {
    return textMatch(i.username, fulltext);
  }
  return items?.filter(doSearch);
}

export function filterFractions(fulltext: string, items: FractionHeaderDto[]) {
  if (!fulltext) {
    return items;
  }

  function doSearch(i: FractionHeaderDto) {
    return textMatch(i.name, fulltext);
  }
  return items?.filter(doSearch);
}

export function filterCustomers(fulltext: string, items: CustomerHeaderDto[]) {
  if (!fulltext) {
    return items;
  }

  function doSearch(i: CustomerHeaderDto) {
    return textMatch(i.name, fulltext);
  }
  return items?.filter(doSearch);
}

/**
 * Get a default view for the account by role.
 * If the account has CUSTOMER rights it will be redirected to dashboard
 * @param authResponse
 */
export const getDefaultView = (authResponse: AuthorizeWebResponse) => {
  if (!authResponse) {
    return undefined;
  }

  const roles = authResponse?.roles;
  if (roles?.length > 0) {
    if (authResponse?.roles?.length === 1 && authResponse?.roles?.[0] === "APP_USER") {
      return "/app/profile";
    }

    const filteredRoles = authResponse?.roles?.filter((value) => value !== "APP_USER");

    const baseRoute = Object.values(entities).find((i) =>
      i?.roleView?.some((j) => filteredRoles?.includes(j))
    )?.baseRoute;
    if (!baseRoute && filteredRoles.includes(ROLE.CUSTOMER_VIEW)) {
      return "/app/dashboard";
    }

    return "/app/" + baseRoute;
  }
  return undefined;
};

/**
 * Pretty coordinates format
 * @param lat
 * @param lon
 */
export function formatCoordinates(lat: number, lon: number) {
  if (!lat || !lon) return "";

  return `${String(lat).substring(0, 8)} / ${String(lon).substring(0, 8)}`;
}

/**
 * Check if the minor version is higher than the given number
 * @param version
 * @param minorVersion
 */
export function minorVersionHigherThan(version: string, minorVersion: number) {
  const parts = version.split(".");
  return parseInt(parts[1]) > minorVersion;
}

const hours = Array.from({ length: 24 }, (_, i) => i);
const minutes = Array.from({ length: 60 }, (_, i) => i);

/**
 * Filter minutes - for disabled minutes in time picker
 * @param value
 */
export function filterMinutes(value: number) {
  return minutes.filter((i) => i < value);
}

/**
 * Filter hours - for disabled hours in time picker
 * @param value
 */
export function filterHours(value: number) {
  return hours.filter((i) => i < value);
}

/**
 * Trims the firmware version number to the first three numbers (MAJOR.MINOR.PATCH)
 * @param version
 */
export function trimAcuFirmwareVersionNumber(version: string) {
  // Match the first three numbers (MAJOR.MINOR.PATCH) and ignore anything after
  const match = version.match(/^(\d+\.\d+\.\d+)/);
  // Return the valid semver part if it exists, otherwise return null
  return match ? match[0] : null;
}
